export const flCookieIcon = {
    data: `
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.42733 11.8884H16.4394V18.1173H7.42733V11.8884ZM9.01769 13.4788V16.527H14.849V13.4788H9.01769Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9333 8.37636C11.0916 8.37636 10.4093 9.05873 10.4093 9.90048V12.6836H8.81889V9.90048C8.81889 8.18041 10.2133 6.786 11.9333 6.786C13.6534 6.786 15.0478 8.18041 15.0478 9.90048V12.6836H13.4574V9.90048C13.4574 9.05873 12.7751 8.37636 11.9333 8.37636Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9333 3.27396C9.43776 3.27396 7.04438 4.26533 5.27974 6.02998C3.51508 7.79463 2.52371 10.188 2.52371 12.6836C2.52371 15.1792 3.51509 17.5726 5.27974 19.3372C7.04438 21.1019 9.43776 22.0932 11.9333 22.0932C14.429 22.0932 16.8223 21.1019 18.587 19.3372C20.3516 17.5726 21.343 15.1792 21.343 12.6836H22.9333C22.9333 15.601 21.7745 18.3989 19.7115 20.4618C17.6486 22.5247 14.8507 23.6836 11.9333 23.6836C9.01597 23.6836 6.21808 22.5247 4.15518 20.4618C2.09228 18.3989 0.93335 15.601 0.93335 12.6836C0.93335 9.76621 2.09228 6.96832 4.15518 4.90542C6.21808 2.84252 9.01597 1.68359 11.9333 1.68359C17.0593 1.68359 19.7025 4.84836 21.727 7.27235C22.0754 7.68941 22.4054 8.08454 22.7266 8.43785L21.5498 9.50765C21.1679 9.08759 20.796 8.64708 20.4208 8.20269C18.3882 5.79527 16.2594 3.27396 11.9333 3.27396Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.343 8.17757V5.26191H22.9334V9.76793H18.4273V8.17757H21.343Z" fill="black"/>
    </svg>
`,
    name: 'cookie' as const
};
